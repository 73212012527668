// @ts-nocheck
import { init, getDeviceId, getSessionId, track } from '@amplitude/analytics-browser'
import { store } from '../services/store/store'
import { executePayloads } from '../shared_utils/execute'
import { apiEvent } from '../services/api/api-event'
import { isGarmentSizePreorder } from '../shared_utils/garment-size'

export const DEBUG_MODE: boolean =
    process.env.DEBUG_MODE === 'true' || process.env.REACT_APP_DEBUG_MODE === 'true'

const areAnalyticsEnabled = () => {
    const configAnalytics = store.getState().config.queryParams.analytics
    return (
        window.location.hostname !== 'localhost' &&
        (configAnalytics === undefined || configAnalytics)
    )
}

const trackingDebug =
    DEBUG_MODE ||
    window.location.hostname === 'localhost' ||
    !!window.location.hostname.match(/\.veesual\.ai$/) ||
    !!window.location.hostname.match(/-veesual\.vercel\.app$/)

const hasAnalytics = () => {
    return true
}

let areAnalyticsLoaded = false
const initTracking = () => {
    // ---- Segment ----
    if (hasAnalytics()) {
        if (areAnalyticsEnabled()) {
            if (trackingDebug) {
                init('14b31c452b81ab5f02276fcba7a343bc', null, {
                    serverUrl: 'https://segment.veesual.ai/event',
                    defaultTracking: false,
                })
            } else {
                init('6745d3ed5a0f89fce03654efe4a92e81', null, {
                    serverUrl: 'https://segment.veesual.ai/event',
                    defaultTracking: false,
                })
            }
        }
        areAnalyticsLoaded = true
    }
}

const getBasicProperties = () => {
    let origin = window.location.href
    if (origin !== null) {
        const originSplit = origin.split('?')
        if (originSplit.length == 2) {
            let originQuery = originSplit[1]
            originQuery = originQuery.replace(
                /(^|&)(utm_[^&#=]*|fbclid|gclid|gclsrc|_ga|mc_cid|mc_eid|_branch_match_id)(=[^&#]*)?/gi,
                ''
            )
            originQuery = originQuery.replace(/^&/, '')
            origin = originSplit[0] + (originQuery != '' ? '?' + originQuery : '')
        }
    }

    const config = store.getState().config.config
    const queryConfig = store.getState().config.queryParams
    const apiConfig = store.getState().config.apiConfig
    const hasMultisize = store.getState().settings.hasMultisize

    const basicProperties = {
        module: 'switch_model',
        client_id: apiConfig?.id,
        client_internal: apiConfig?.internal,
        client_name: apiConfig?.name,
        group_id: apiConfig?.group_id || null,
        group_name: apiConfig?.group_name || null,
        experience_id: apiConfig?.experience_id || null,
        experience_name: apiConfig?.experience_name || null,
        locale: queryConfig.locale || null,
        domain: queryConfig.domain || null,
        origin: origin,
        cta_location: config.cta_location || null,
        version: 1.0,
        ...(hasMultisize ? { has_multisizing: hasMultisize } : null),
    }

    for (const field of ['garment_id', 'look_id', 'focus']) {
        if (queryConfig[field]) {
            basicProperties[`init_${field}`] = queryConfig[field]
        }
    }
    if (basicProperties.init_look_id) {
        basicProperties.init_garment_look_id = basicProperties.init_look_id.replace(/^[^&]+&/, '')
    }

    return basicProperties
}

const getPageNameAndProperties = () => {
    return { name: 'Switch Model', properties: {} }
}

const doTrackPage = async () => {
    // ---- Ajout des basics ----
    const pageAndProperties = getPageNameAndProperties()
    const finalProperties = Object.assign(getBasicProperties(), pageAndProperties.properties)

    // ---- Debug ----
    if (trackingDebug) {
        console.log('trackPage', pageAndProperties.name, finalProperties)
    }

    // ---- Lancement de l'event ----
    if (areAnalyticsEnabled()) {
        if (areAnalyticsLoaded) {
            track(`Viewed ${pageAndProperties.name} Page`, finalProperties)
        }
    }
}

const getPropertyHavingKey = (properties?: any, key) => {
    // ---- Traduction des properties ----
    if (properties !== undefined && properties !== null) {
        if (Array.isArray(properties)) {
            for (const one of properties) {
                const property = getPropertyHavingKey(one, key)
                if (property !== null) {
                    return property
                }
            }
        } else if (typeof properties[key] !== 'undefined') {
            return properties
        }
    }
    return null
}

const addPrefixToProperties = (prefix, properties: any) => {
    const prefixed = {}
    for (const key in properties) {
        prefixed[prefix + key] = properties[key]
    }
    return prefixed
}

const convertProperties = (properties?: any) => {
    // ---- Traduction des properties ----
    let convertedProperties = {}
    if (properties !== undefined && properties !== null) {
        if (Array.isArray(properties)) {
            for (const one of properties) {
                convertedProperties = Object.assign(convertedProperties, convertProperties(one))
            }
        } else if (typeof properties.garment_id !== 'undefined') {
            convertedProperties = {
                item_internal_id: properties.garment_id,
                item_external_id: properties.product_external_id || null,
                item_name: properties.product_name,
                item_type: properties.garment_type,
                item_price: properties.product_price,
            }
        } else if (typeof properties.model_id !== 'undefined') {
            convertedProperties = {
                model_id: properties.model_id,
                model_name: properties.model_name,
                model_height: properties.model_height,
                model_garment_size: properties.model_garment_size,
            }
        } else if (typeof properties.look_id !== 'undefined') {
            const garmentTypes = store.getState().config.apiConfig?.garment_types || [
                'TOP',
                'BOTTOM',
                'DRESS',
                'OUTERWEAR',
            ]
            let nbItems = 0
            let totalPrice = 0
            const types = []
            for (const type of garmentTypes) {
                const typeLower = type.toLowerCase()
                if (properties[typeLower]) {
                    nbItems += 1
                    totalPrice += properties[typeLower].product_price
                    types.push(type)
                }
            }
            convertedProperties = Object.assign(
                {
                    outfit_id: properties.look_id,
                    outfit_nb_items: nbItems,
                    outfit_total_price: totalPrice,
                    outfit_types: types.join(','),
                },
                convertProperties(properties.model)
            )
            for (const type of garmentTypes) {
                const typeLower = type.toLowerCase()
                if (properties[typeLower]) {
                    convertedProperties = Object.assign(
                        convertedProperties,
                        addPrefixToProperties(
                            typeLower + '_',
                            convertProperties(properties[typeLower])
                        )
                    )
                }
            }
        } else if (typeof properties === 'object') {
            convertedProperties = properties
        }
    }
    return convertedProperties
}

const convertFinalProperties = (name: string, properties: Record<string, any>) => {
    // ---- Surcharge globale si besoin ----
    if (name === 'Item Added to cart') {
        return Object.assign(properties, {
            revenue: properties.item_price,
            price: properties.item_price,
            currency: 'EUR',
            // ---- Old segment fields ----
            $revenue: properties.item_price,
            $price: properties.item_price,
            $quantity: 1,
        })
    }

    return properties
}

const postInitEventWhenDeviceAndSession = (
    type: string,
    finalProperties: Record<string, any>,
    n = 0
) => {
    const deviceId = getDeviceId()
    const sessionId = getSessionId()
    if (deviceId !== undefined && sessionId !== undefined) {
        store.dispatch(
            apiEvent.endpoints.postEvent.initiate(
                Object.assign({ type, deviceId, sessionId }, finalProperties)
            )
        )
    } else if (n < 10) {
        setTimeout(() => {
            postInitEventWhenDeviceAndSession(type, finalProperties, n + 1)
        }, 500)
    }
}

const getEileenFisherGaProductData = (garmentProperty, finalProperties = null, list = null) => {
    const colorMatch = garmentProperty.product_external_url.match(
        /(\?|&)dwvar_[^&]+_color=([0-9]+)/
    )

    return {
        name: garmentProperty.product_name,
        id: garmentProperty.product_external_id,
        product_url: garmentProperty.product_external_url,
        product_image_url: garmentProperty.image_url,
        price: finalProperties ? finalProperties.item_price : garmentProperty.product_price,
        dimension98: '',
        dimension10: 'closet',
        dimension9: '',
        dimension11: '',
        dimension18: '',
        dimension19: 'NA',
        dimension20: 'NA',
        dimension21: '',
        dimension22: 'closet',
        dimension23: '',
        dimension24: '',
        dimension25: garmentProperty.product_color,
        dimension26: '',
        dimension27: colorMatch ? colorMatch[2] : '',
        dimension28: '',
        dimension29: '',
        dimension30: isGarmentSizePreorder(null, garmentProperty.product_sizes)
            ? 'pre-order'
            : 'IN_STOCK',
        dimension31: finalProperties ? finalProperties.item_size_selected_label : '',
        dimension32: garmentProperty.product_price_original || garmentProperty.product_price,
        dimension33: '',
        dimension35: '',
        dimension76: '',
        dimension77: '',
        category: '',
        quantity: '1',
        metric1: finalProperties ? finalProperties.item_price : garmentProperty.product_price,
        metric2: garmentProperty.product_price_original || garmentProperty.product_price,
        variant: finalProperties
            ? finalProperties.item_size_selected
            : garmentProperty.product_sizes[0].offer_id,
        brand: 'EF',
        item_list_name: list,
    }
}

const doTrackEvent = async (name: string, properties?: any, category: string = null) => {
    // ---- Traduction des properties ----
    const convertedProperties = convertProperties(properties)
    const pageAndProperties = getPageNameAndProperties()
    const convertedPageProperties = Object.assign(
        { page_view: pageAndProperties.name },
        pageAndProperties.properties
    )
    const convertedCategory = { category: category }

    // ---- Ajout des basics ----
    const finalProperties = convertFinalProperties(
        name,
        Object.assign(
            convertedCategory,
            getBasicProperties(),
            convertedPageProperties,
            convertedProperties
        )
    )

    // ---- Debug ----
    if (trackingDebug) {
        console.log('trackEvent', name, finalProperties)
    }

    // ---- Lancement de l'event ----
    if (areAnalyticsEnabled()) {
        if (areAnalyticsLoaded) {
            track(name, finalProperties)
        }
        if (store.getState().config.apiConfig?.enable_event) {
            switch (name) {
                case 'Init':
                    postInitEventWhenDeviceAndSession('INIT', finalProperties)
                    break
                case 'Item Added to cart':
                    postInitEventWhenDeviceAndSession('ADD_TO_CART', finalProperties)
                    break
                case 'More models Submitted':
                    postInitEventWhenDeviceAndSession('MORE_MODELS', finalProperties)
                    break
            }
        }
        // ---- Gemo ----
        if (window.location.hostname.match(/(^|\.)gemo\.fr$/)) {
            // ---- En fonction de l'event ----
            let eventName = null,
                eventData = null
            switch (name) {
                case 'Init':
                    eventName = 'veesual.switch_model'
                    eventData = {}
                    break
                case 'Item Added to cart':
                    eventName = 'veesual.add_to_cart'
                    eventData = {
                        item_type: finalProperties.item_type,
                        item_price: finalProperties.item_price,
                        item_name: finalProperties.item_name,
                        item_size_selected_label: finalProperties.item_size_selected_label,
                        item_external_id: finalProperties.item_external_id,
                    }
                    break
            }
            if (eventName !== null && eventData !== null) {
                executePayloads([
                    {
                        type: 'javascript',
                        payload: `pa.sendEvent('${eventName.replace(
                            /'/,
                            "\\'"
                        )}', JSON.parse('${JSON.stringify(eventData).replace(/'/g, "\\'")}'));`,
                    },
                ])
            }
        }
        // ---- Contentsquare ----
        if (window.location.hostname.match(/(^|\.)gemo\.fr$/)) {
            // ---- Envoi d'une liste restreinte d'events ----
            if (['Init', 'Item Added to cart'].indexOf(name) !== -1) {
                executePayloads([
                    {
                        type: 'event',
                        partner: ['contentsquare'],
                        payload: {
                            name: name,
                            properties: finalProperties,
                            timestamp: Date.now(),
                        },
                    },
                ])
            }
        }
        // ---- Claudie pierlot ----
        if (window.location.hostname.match(/(^|\.)claudiepierlot\.com$/)) {
            // ---- En fonction de l'event ----
            if (
                [
                    'Init',
                    'Item Added to cart',
                    'Swipe right',
                    'Swipe left',
                    'More models Visible',
                ].indexOf(name) !== -1
            ) {
                executePayloads([
                    {
                        type: 'javascript',
                        payload: `window.dataLayer = window.dataLayer || []; window.dataLayer.push({
                            'event': 'veesual_switchmodel_${name
                                .toLowerCase()
                                .replace(/\s+/g, '_')
                                .replace(/'/g, "\\'")}',
                            'veesual': JSON.parse('${JSON.stringify(finalProperties).replace(
                                /'/g,
                                "\\'"
                            )}')
                        });`,
                    },
                ])
            }

            // ---- Lancement du init dans contentsquare ----
            if (name == 'Init') {
                executePayloads([
                    {
                        type: 'javascript',
                        payload: `window._uxa = window._uxa || []; window._uxa.push(["trackDynamicVariable", {key: "Veesual Switch Model Init", value: "true"}]);`,
                    },
                ])
            }
        }
        // ---- Eileen Fisher ----
        if (finalProperties.client_internal.match(/^eileenfisher/)) {
            // ---- Raccourcis ----
            const listName = 'change_your_model'
            const lookProperty = getPropertyHavingKey(properties, 'look_id')
            const garmentProperty = getPropertyHavingKey(properties, 'garment_id')

            // ---- En fonction de l'event ----
            let eventData = null
            switch (name) {
                case 'Item Added to cart':
                    if (garmentProperty !== null) {
                        eventData = {
                            event: 'addToCart',
                            ecommerce: {
                                add: {
                                    products: [
                                        getEileenFisherGaProductData(
                                            garmentProperty,
                                            finalProperties,
                                            listName
                                        ),
                                    ],
                                },
                            },
                        }
                    }
                    break
                case 'Open Modal':
                case 'Swipe left':
                case 'Swipe right':
                    if (
                        lookProperty !== null &&
                        finalProperties.init_focus &&
                        lookProperty[finalProperties.init_focus.toLowerCase()]
                    ) {
                        const itemProductData = getEileenFisherGaProductData(
                            lookProperty[finalProperties.init_focus.toLowerCase()],
                            null,
                            listName
                        )
                        itemProductData.position = finalProperties.carousel_index || 0
                        eventData = {
                            event: 'productImpressions',
                            ecommerce: {
                                currencyCode: 'USD',
                                impressions: [itemProductData],
                            },
                        }
                    }
                    break
                case 'Size Selected':
                case 'MultiSize Selected':
                    if (
                        finalProperties.old_item_size_selected_label !==
                        finalProperties.item_size_selected_label
                    ) {
                        eventData = {
                            event: 'pdpSize',
                            eventChange: `${
                                finalProperties.old_item_size_selected_label ||
                                finalProperties.model_garment_size
                            }|${finalProperties.item_size_selected_label || ''}`,
                            eventProduct: finalProperties.item_external_id,
                            eventLocation: listName,
                        }
                    }
                    break
            }
            if (eventData !== null) {
                executePayloads([
                    {
                        type: 'javascript',
                        payload: `window.dataLayer = window.dataLayer || []; window.dataLayer.push(
                            JSON.parse('${JSON.stringify(eventData).replace(/'/, "\\'")}')
                        );`,
                    },
                ])
            }
        }
    }
}

const canUseAnalytics = () => {
    if (hasAnalytics()) {
        if (!areAnalyticsLoaded) {
            initTracking()
            doTrackEvent('Init', null, 'Switch Model')
        }
        return true
    }
    return false
}

export const trackPage = async () => {
    if (canUseAnalytics()) {
        doTrackPage()
    }
}

const eventQueue = []
export const trackEvent = async (name: string, properties?: any, category: string = null) => {
    if (store.getState().apiConfig) {
        if (canUseAnalytics()) {
            while (eventQueue.length) {
                const eventData = eventQueue.shift()
                doTrackEvent(eventData.name, eventData.properties, eventData.category)
            }
            doTrackEvent(name, properties, category)
        }
    } else {
        eventQueue.push({ name, properties, category })
    }
}
